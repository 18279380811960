export const compliments = [
	'OMGOMGOMG 😍',
	'Mmmmmmmmm 🤤',
	'Sooo goooood! 🤯',
	'nom nom nom 😋',
	'Soda-licious! 🥤',
	'A-maize-ing! 🌽',
	'Holy crépe! 🥞',
	'The berry best! 🍓',
	'Fudging awesome 🤩',
	'Souper! 🥫',
	'Mint! 🪴',
	'How a-peeling! 🍌',
	'Holy guacamole! 🥑',
	'The very zest! 🍋',
	'Thanks a latte! ☕️',
	'Cherry good! 🍒',
	'One in a melon! 🍉',
	'S-peach-less! 🍑',
];
