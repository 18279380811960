import React from 'react';
import { Contact, Suggestions } from './index';

export default function Footer() {
	return (
		<>
			<Suggestions />
			<Contact />
		</>
	);
}
